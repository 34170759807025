/* .rangeDatePicker {
    width: 260px;
    display: flex;
    border: 1px solid #d0d0d0;
    box-shadow: none;
    font-family: NanumSquareRound;
    letter-spacing: -0.3px;
} */

/* datepicker input 창 */
.react-datepicker-wrapper input {
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    font-family: NanumSquareRound;
    letter-spacing: -0.3px;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    max-width: 300px;
    height: 0px;
}

.react-datepicker-wrapper {
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker-popper {
    padding: 0px;
}

/* 달력  */
.react-datepicker {
    border: 1px solid #d0d0d0 !important;
    box-shadow: none !important;
    width: 260px;
    display: flex;
    border: 1px solid #d0d0d0;
    box-shadow: none;
    font-family: NanumSquareRound;
    letter-spacing: -0.3px;
}

.react-datepicker__header {
    background-color: #fff;
    border: none;
    padding: 0px;
}

.react-datepicker__month {
}

.react-datepicker__month-container {
    padding-bottom: 12px;
}

.react-datepicker__week {
    height: 32px;
    margin: 4px 0px;
}

/* 오늘 선택 불가  */
.react-datepicker__day--today {
    background-color: #fff;
    color: #ffcd00;
}

.react-datepicker__day--today:hover {
    background-color: #fff;
    color: #cccccc;
    cursor: default;
}

.react-datepicker__day--range-start {
    background-color: #ffcd00 !important;
    border-radius: 4px;
    color: #000;
}
.react-datepicker__day--in-range {
    background-color: #fffbe5;
    border-radius: 4px;
    color: #000;
}
.react-datepicker__day--range-end {
    background-color: #ffcd00;
    border-radius: 4px;
    color: #000;
}
.react-datepicker__day--keyboard-selected {
    background-color: #ffcd00;
    border-radius: 4px;
    color: #000;
}
.react-datepicker__day--disabled {
    color: #cccccc;
    background-color: #fff;
}
.react-datepicker__day--disabled:hover {
    color: #cccccc;
    background-color: #fff;
}
.react-datepicker__day:active {
    border: none;
}
/* 범위 선택 도중 호버하고있는 컴포넌트 */
.react-datepicker__day--in-range:hover {
    background-color: #ffcd00;
    color: #000;
}

.react-datepicker__day-name {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0px;
    font-family: NanumSquareRound;
    color: #1a468b;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.39px;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0px;
    font-family: NanumSquareRound;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.42px;
}

/* 과거 날짜 */
.react-datepicker__day .react-datepicker__day--disabled {
    background-color: #fff;
    color: #cccccc !important;
    > span {
        color: #cecece;
    }
}

.react-datepicker__day--outside-month {
    color: #cccccc;
}

/* select 전 드래그 중 날짜 기간 */
.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range {
    background-color: #ffcd00;
    border-radius: 4px;
    color: #000;
}

.react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range {
    background-color: #ffcd00;
    border-radius: 4px;
    color: #000;
}
.react-datepicker__day--in-selecting-range {
    background-color: #ffcd00 !important;
    border-radius: 4px;
    color: #000;
}
