.singleDatePicker {
    width: 260px;
    display: flex;
    border: none;
    font-family: NanumSquareRound;
    letter-spacing: -0.3px;
}

/* datepicker input 창 */
.react-datepicker-wrapper input {
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    font-family: NanumSquareRound;
    letter-spacing: -0.3px;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    max-width: 300px;
    height: 36px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__triangle {
    display: none;
}

/* 달력  */
.react-datepicker {
    border: none;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
}

.react-datepicker__header {
    background-color: #fff;
    border: none;
    padding: 0px;
}

.react-datepicker__month {
    margin-top: 10px;
}

.react-datepicker__month-container {
    padding-bottom: 12px;
}

.react-datepicker__week {
    height: 32px;
}

/* 범위 선택 도중 호버하고있는 컴포넌트 */
.react-datepicker__day:hover {
    background-color: #ffcd00;
    color: #000;
}
.react-datepicker__day--selected {
    background-color: #ffcd00;
    color: #000;
}
.react-datepicker__day--keyboard-selected {
    background-color: #fff;
}
.react-datepicker__day-name {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0px;
    font-family: NanumSquareRound;
    color: #1a468b;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.39px;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0px;
    font-family: NanumSquareRound;
    /* color: #000; */
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.42px;
}

/* 과거 날짜 */
.react-datepicker__day--disabled:hover {
    background-color: #fff;
    color: #cccccc;
    > span {
        color: #cecece;
    }
    :hover {
        background-color: #fff;
    }
}

.react-datepicker__day--outside-month {
    color: #cccccc;
}

.button .span {
    font-size: 13px;
    font-weight: 800;
    color: #000;
    line-height: 16px;
    font-family: NanumSquareRound;
}

.button:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
}
