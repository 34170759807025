.react-datepicker__current-month {
    margin-top: 10px;
    width: 100%;
}

.react-datepicker__week {
    height: 32px;
    margin: 4px 0px;
}

.react-datepicker__month-container {
    padding: 0px;
    width: 260px;
}

.react-datepicker__year-read-view--down-arrow {
    top: -1px !important ;
    transform: rotate(135deg);
}

.react-datepicker__month-read-view--down-arrow {
    top: -1px !important ;
    transform: rotate(135deg);
}
